import * as React from 'react';
import { Link } from 'gatsby';

import {
  Text,
  BlogImage,
  BlogInfographics,
  BlogLink,
} from '../../../components';

import { PageNew, SectionBlog } from '../../../fragments';

import routes from '../../../routes';

import infographic from '../../../images/educational-corner/blog12/infografika.png';
import speechTherapyExercises from '../../../images/educational-corner/blog12/Logopedske-vjezbe-kod-kuce-uz-Kokolingo.png';
import parentTherapistRelationship from '../../../images/educational-corner/blog12/Odnos-logopeda-i-roditelja.png';

const ImportanceOfSpeechTherapyAtHome = () => {
  return (
    <PageNew
      title="KOKOLINGO – Aplikacija za Logopedske Vježbe kod Kuće"
      description="Kako bi se olakšalo provođenje logopedskih vježbi za pravilan izgovor,
      osmišljena je digitalna vježbenica Kokolingo. Igrom do pravilnog
      izgovora glasa."
      headline="Važnost provođenja logopedskih vježbi kod kuće"
    >
      <Text as="p">
        Logoped je stručnjak koji pomaže osobama s komunikacijskim i
        jezično-govornim poremećajima. Bavi se edukacijom javnosti o ovim
        poremećajima, provodi dijagnostiku i terapiju s djecom i odraslima te
        savjetuje roditelje ili skrbnike kako poticati dječji razvoj. Roditelj i
        logoped trebaju uspostaviti partnerski odnos, bilo da je riječ o
        mucanju, oštećenju sluha ili{' '}
        <Link
          to={routes.SPEECH_DISORDER}
          title="Artikulacijski poremećaji"
          target="_blank"
          rel="noopener noreferrer"
          hrefLang="hr"
        >
          <b>artikulacijskom poremećaju</b>
        </Link>
        , da bi dijete moglo napredovati.
      </Text>
      <Text as="h2">Odnos logopeda i roditelja</Text>
      <Text as="p">
        Ako roditelj primijeti da njegovo dijete ima određenu teškoću, u sferi
        komunikacije, jezika ili govora, potražit će pomoć logopeda. Nakon
        detaljne dijagnostike logoped će imati dovoljno informacija da zaključi
        je li riječ o razvojnoj teškoći, koju će dijete s vremenom svladati, ili
        se radi o poremećaju koji zahtijeva logopedsku terapiju. Važno je da
        logoped jasno prezentira svoje zaključke kako bi roditelj znao što
        očekivati. Logoped bi trebao pružiti i emocionalnu podršku roditeljima,
        posebice ako dijete ima poremećaj koji zahtijeva dugotrajnu logopedsku
        terapiju ili terapiju i podršku ostalih stručnjaka.
      </Text>
      <Text as="p">
        Različite vrste poremećaja zahtijevaju i različite pristupe u radu te je
        različito i vrijeme trajanja terapije. Ponekad roditelji mogu očekivati
        da će terapija trajati kratko ili da će napredak biti vidljiv za
        nekoliko dana ili tjedana. Prije početka rada važno je da logoped iznese
        približan vremenski okvir trajanja terapije. Tako roditelj zna što
        očekivati, što smanjuje osjećaj neizvjesnosti. Budući da je svako dijete
        individualno, ponekad se očekivani napredak neće dogoditi u točno
        određenom razdoblju. Materijali i postupci za rad stoga moraju biti
        individualizirani prema potrebama djeteta. Poželjno je nakon određenog
        razdoblja revidirati napredak te modificirati postupke ako napredak nije
        vidljiv.
      </Text>
      <BlogImage
        imgSrc={parentTherapistRelationship}
        imgAlt="Odnos logopeda i roditelja"
        imgTitle="Odnos logopeda i roditelja"
      />
      <Text as="h2">Logopedske vježbe kod kuće</Text>
      <Text as="p">
        Budući da je broj logopeda u Hrvatskoj malen, logopedsku terapiju dijete
        će pohađati uglavnom jednom tjedno, u trajanju od 45 minuta. To je jako
        kratko vrijeme da se ostvari znatniji napredak i stoga logopedi izrađuju
        materijale kojima će se roditelji koristiti s djecom kod kuće. Najviše
        takvih materijala izrađuje se za djecu s artikulacijskim poremećajima.
        Prije provođenja vježbi logoped će dati smjernice za rad. Važno je da
        roditelji ili skrbnici provode vježbe kod kuće jer bez njih nema
        napretka, posebice ako je riječ o uvježbavanju pravilnog izgovora.
        Naravno, uz konkretne vježbe logoped će dati i dodatne materijale, kao
        što su labirinti za uvježbavanje{' '}
        <a
          href="https://www.artrea.com.hr/onlinegrafomotorika.html"
          target="_blank"
          rel="noopener noreferrer"
          hrefLang="hr"
        >
          <b>grafomotorike</b>
        </a>{' '}
        ili različite bojanke koje mogu poslužiti kao motivatori za daljnji rad
        ili odmor od konkretnih vježbi. Tijekom rada roditelj treba pružiti
        primjer djetetu, ohrabrivati ga i poticati. Roditelji su osobe koje su
        svakodnevno u kontaktu s djetetom te su stoga ključni za njegov
        napredak.
      </Text>
      <BlogImage
        imgSrc={speechTherapyExercises}
        imgAlt="Logopedske vjezbe kod kuce uz Kokolingo"
        imgTitle="Logopedske vjezbe kod kuce uz Kokolingo"
      />
      <Text as="h2">Kokolingo aplikacija za vježbe kod kuće</Text>
      <Text as="p">
        Artikulacijski poremećaji među najčešćim su govornim poremećajima. U
        terapiji ovih poremećaja primjenjuju se razni materijali - liste riječi,
        memori kartice, simboli pojmova itd. Budući da ova vrsta poremećaja
        zahtijeva sustavno ponavljanje određenih riječi, s naglašenim pravilnim
        izgovorom određenoga glasa, podrazumijeva i vježbe kod kuće. Bez
        svakodnevnog uvježbavanja pravilnog izgovora teško će se ostvariti
        napredak.
      </Text>
      <Text as="p">
        Kako bi se olakšalo provođenje logopedskih vježbi za pravilan izgovor,
        osmišljena je digitalna vježbenica <BlogLink>Kokolingo</BlogLink>.
        Zadatci u njoj poredani su redom, od lakših prema težima, od
        jednostavnijih ka složenijima, prema principima rada logopedske terapije
        artikulacijskih poremećaja. Ova vježbenica sadržava mnoge liste riječi
        za uvježbavanje pravilnoga izgovora koje su predstavljene kao različite
        aktivnosti. Tako se smanjuje zamor koji se može javiti nakon uzastopnog
        ponavljanja istih riječi jer su liste riječi predstavljene kao zabavne
        aktivnosti.
      </Text>
      <Text as="p">
        Budući da je riječ o digitalnoj vježbenici,{' '}
        <BlogLink>Kokolingo</BlogLink> će lakše motivirati dijete za rad nego
        klasični materijali. Kao i kod klasičnih vježbi, u odrađivanju zadataka
        treba biti sustavan - tek kada dijete svlada sve zadatke na jednom
        području, treba ići na iduće.
      </Text>
      <Text as="p">
        Napredak zahtijeva vrijeme i svakodnevni rad. Pohađanjem logopedske
        terapije i provođenjem vježbi kod kuće dijete će napredovati u
        komunikacijskom i jezično-govornom razvoju. Da bi se to postiglo,
        ključno je odrađivati vježbe kod kuće, odnosno poticati dijete u
        prirodnom okružju.
      </Text>
      <Text as="p">Krešimir Perša, mag. logoped.</Text>
      <BlogInfographics infographicSrc={infographic} />
      <SectionBlog />
    </PageNew>
  );
};

export default ImportanceOfSpeechTherapyAtHome;
